import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'max-area-content',
  templateUrl: './max-area-content.component.html',
  standalone:true,
})
export class MaxAreaContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
